import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ptBR } from 'date-fns/locale';
import * as datefns from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { format, toDate } from 'date-fns-tz';
import clsx from 'clsx';
import {
  Badge,
  CircularProgress,
  Checkbox,
  Chip,
  IconButton,
  InputLabel,
  FormControl,
  FormControlLabel,
  MenuItem,
  Paper,
  TextField as TextFieldCore,
  Grid,
  Hidden,
  Select,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import {
  AttachFile,
  Brightness1,
  FormatListBulleted,
  Clear,
} from '@material-ui/icons';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { MaterialTableProps, Query } from 'material-table';
import { Autocomplete } from '@material-ui/lab';
import useUtility from '../../hooks/useUtility';
import api from '../../services/api';
import Page from '../../components/Page';
import Filter from '../../components/Filter';
import MaterialTableX from '../../components/MaterialTable';
import Dialog from '../../components/Dialog';
import DialogForm from '../../components/DialogForm';
import TextField from '../../components/TextField';
import SelectTreeView from '../../components/SelectTreeView';
import {
  IFilial,
  IFilters,
  IDepartamento,
  IDepartamentoTree,
  ISolicitacao,
  ITipoSolicitacao,
  ISolicitacaoArquivo,
  ISolicitacaoHistorico,
  ITypeSelection,
} from '../../models';
import snack from '../../components/SnackbarUtilsConfigurator';
import DatePicker from '../../components/DatePicker';
import ImageListDocuments from './ImageListDocuments';
import departmentTree from '../../utils/getDepartmentTree';
import styles from './styles';
import useLogin from '../../hooks/useLogin';

interface IChip {
  id: string;
  label: string;
}

const schema = yup
  .object()
  .shape({
    prioridade: yup.string().required('Este campo é obrigatório'),
    descricao: yup.string().required('Este campo é obrigatório'),
    filial: yup.object().required('Este campo é obrigatório').nullable(),
    departamentoId: yup.string().required('Este campo é obrigatório'),
    tipoSolicitacao: yup
      .object()
      .required('Este campo é obrigatório')
      .nullable(),
    observacao: yup.string().required('Este campo é obrigatório'),
  })
  .required();

const schemaFilters = yup
  .object()
  .shape({
    dateInclusionInitial: yup.date().nullable(),
    dateInclusionFinal: yup.date().nullable(),
    dateForecastInitial: yup.date().nullable(),
    dateForecastFinal: yup.date().nullable(),
  })
  .required();

const Requests = () => {
  const classes = styles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isFetch, setIsFetch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [isDepLoading, setIsDepLoading] = useState(false);
  const [isOpenDropZone, setIsOpenDropZone] = useState(false);
  const { closeDialog, utilitySelector, closeFilter } = useUtility();
  const { userSelector } = useLogin();
  const [department, setDepartment] = useState<IDepartamentoTree[]>([]);
  const [filterDepartment, setFilterDepartment] = useState<IDepartamentoTree[]>(
    []
  );
  const [deps, setDeps] = useState<IDepartamento[]>([]);
  const [branch, setBranch] = useState<IFilial[]>([]);
  const [requestType, setRequestType] = useState<ITipoSolicitacao[]>([]);
  const [requestHistory, setRequestHistory] = useState<ISolicitacaoHistorico[]>(
    []
  );
  const [requestFile, setRequestFile] = useState<ISolicitacaoArquivo[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [solicitacao, setSolicitacao] = useState<ISolicitacao>();
  const [openHistory, setOpenHistory] = useState(false);
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);
  const [modifyArq, setModifyArq] = useState(0);
  const [nivel, setNivel] = useState(0);
  const [chip, setChip] = useState<IChip[]>([]);
  const [pageX, setPageX] = useState<Query<any>>();
  const [pageRequest, setPageRequest] = useState();
  const [totalCount, setTotalCount] = useState(0);
  const [defaultFilter, setDefaultFilter] = useState<IFilters>({
    situation: {
      id: 'P',
      description: 'Pendente',
    },
    priority: null,
    branch: null,
    requestType: null,
    dateInclusionInitial: new Date(),
    dateInclusionFinal: new Date(),
    dateForecastInitial: null,
    dateForecastFinal: null,
    departamentId: undefined,
    myRequest: true,
  });
  const [storageFilter, setStorageFilter] = useState(
    localStorage.getItem('filterRequest')
  );
  const [filter, setFilter] = useState<IFilters>(
    storageFilter ? JSON.parse(storageFilter) : defaultFilter
  );
  const [erroDateForecast, setErroDateForecast] = useState<string>();
  const [erroDateInclusion, setErroDateInclusion] = useState<string>();
  const [situationType, setSituationType] = useState<ITypeSelection[]>([
    {
      id: 'P',
      description: 'Pendente',
    },
    {
      id: 'L',
      description: 'Solicitação de Aprovação',
    },
    {
      id: 'S',
      description: 'Solicitação de Cancelamento',
    },
    {
      id: 'C',
      description: 'Cancelada',
    },
    {
      id: 'A',
      description: 'Aguardando',
    },
    {
      id: 'E',
      description: 'Andamento',
    },
    {
      id: 'R',
      description: 'Aprovada',
    },
    {
      id: 'F',
      description: 'Finalizada',
    },
  ]);

  const tableRef = React.useRef<MaterialTableProps<any>>();

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm<ISolicitacao>({
    resolver: yupResolver(schema),
  });

  const {
    control: controlFilters,
    handleSubmit: handleSubmitFilters,
    reset: resetFilters,
    getValues: getValuesFilters,
    formState: { errors: errorsFilters },
  } = useForm<IFilters>({
    resolver: yupResolver(schemaFilters),
  });

  const returnColorPriority = (prioridade: string) => {
    if (prioridade === '3') {
      return (
        <Tooltip title="Prioridade baixa" placement="top-start">
          <Brightness1 style={{ color: '#006600', fontSize: 14 }} />
        </Tooltip>
      );
    }

    if (prioridade === '2') {
      return (
        <Tooltip title="Prioridade normal" placement="top-start">
          <Brightness1 style={{ color: '#e6e600', fontSize: 14 }} />
        </Tooltip>
      );
    }

    if (prioridade === '1') {
      return (
        <Tooltip title="Prioridade alta" placement="top-start">
          <Brightness1 style={{ color: '#990000', fontSize: 14 }} />
        </Tooltip>
      );
    }

    return <Brightness1 style={{ color: '#000000', fontSize: 14 }} />;
  };

  const columns = [
    {
      title: '',
      field: 'id',
      hidden: false,
      render: (rowData: ISolicitacao) =>
        returnColorPriority(rowData.prioridade),
      cellStyle: {
        width: '0.1%',
      },
    },
    {
      title: 'Código',
      field: 'codigo',
      hidden: false,
      cellStyle: {
        width: '5%',
      },
    },
    {
      title: 'Descrição',
      field: 'descricao',
      hidden: false,
      cellStyle: {
        width: '41.99%',
      },
    },
    {
      title: 'Data Emissão',
      field: 'datainclusao',
      hidden: false,
      render: (rowData: ISolicitacao) => (
        <div>
          {rowData.dataInclusao &&
            format(toDate(rowData.dataInclusao), 'dd/MM/yyyy HH:mm:ss')}
        </div>
      ),
      cellStyle: {
        width: '15%',
      },
    },
    {
      title: 'Previsão',
      field: 'dataPrevisao',
      hidden: false,
      render: (rowData: ISolicitacao) => (
        <div>
          {rowData.dataPrevisao &&
            format(toDate(rowData.dataPrevisao), 'dd/MM/yyyy')}
        </div>
      ),
      cellStyle: {
        width: '8%',
      },
    },
    {
      title: 'Departamento',
      field: 'descricaoDepartamento',
      hidden: false,
      sorting: false,
      cellStyle: {
        width: '15%',
      },
    },
    {
      title: 'Situação',
      field: 'descricaoSituacao',
      hidden: false,
      searchable: false,
      sorting: false,
      render: (rowData: ISolicitacao) => (
        <div>
          {rowData.descricaoSituacao}
          <IconButton
            onClick={() => {
              setSolicitacao(rowData);
              getRequestsHistory(rowData.id);
              setOpenHistory(true);
            }}
            size="small"
          >
            <Tooltip title="Visualizar Histórico" placement="bottom">
              <FormatListBulleted fontSize="small" />
            </Tooltip>
          </IconButton>
        </div>
      ),
      cellStyle: {
        width: '10%',
      },
    },
    {
      title: 'Anexos',
      field: 'arquivos',
      hidden: false,
      searchable: false,
      sorting: false,
      render: (rowData: ISolicitacao) => (
        <div>
          <IconButton
            size="small"
            onClick={() => {
              setSolicitacao(rowData);
              getRequestFile(rowData.id);
              setIsOpenDropZone(true);
            }}
          >
            <Badge
              color="primary"
              badgeContent={rowData.arquivos || 0}
              max={99}
              variant="dot"
            >
              <AttachFile fontSize="small" />
            </Badge>
          </IconButton>
        </div>
      ),
      cellStyle: {
        width: '5%',
      },
    },
  ];

  const columnsHistory = [
    {
      title: 'Data inclusão',
      field: 'dataInclusao',
      hidden: false,
      cellStyle: {
        width: '10%',
      },
      render: (rowData: ISolicitacao) => (
        <div>{format(toDate(rowData.dataInclusao), 'dd/MM/yyyy HH:mm:ss')}</div>
      ),
    },
    {
      title: 'Observação',
      field: 'observacao',
      hidden: false,
      cellStyle: {
        width: '80%',
      },
    },
    {
      title: 'Situação',
      field: 'descricaoSituacao',
      hidden: false,
      cellStyle: {
        width: '10%',
      },
    },
  ];

  const actionsX: any[] = [];

  const refreshQueryRequest = (onFilter: boolean) => {
    if (tableRef.current) {
      (tableRef.current as any).onQueryChange();

      if (onFilter) {
        localStorage.setItem('filterRequest', JSON.stringify(filter));
      }
    }
  };

  const refreshPage = async () => {
    setIsLoadingPage(true);
    try {
      await getBranches();
      await getRequestType();
    } finally {
      setIsLoadingPage(false);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      history.push('/');
    }

    const strPageRequest = localStorage.getItem('pageRequest');

    if (strPageRequest) {
      const pageSet = JSON.parse(strPageRequest);

      if (pageSet) {
        setPageRequest(pageSet);
      }
    }

    refreshPage();
  }, []);

  useEffect(() => {
    resetFilters(filter);
  }, [storageFilter]);

  useEffect(() => {
    refreshQueryRequest(true);
  }, [filter]);

  useEffect(() => {
    setNivel(1);

    async function fetchRequest() {
      await api
        .get<ISolicitacao>(`api/Solicitacao/Get/${utilitySelector.dialogId}`)
        .then(result => {
          const nDep = result.data.departamento?.nivel ?? 0;

          if (nDep > 1) {
            setNivel(nDep - 1);
          }

          reset(result.data);
          setIsFetch(false);
        })
        .catch(() => {
          setIsFetch(false);
        });
    }

    if (utilitySelector.dialogOpen) {
      if (utilitySelector.dialogId) {
        setIsFetch(true);
        fetchRequest();
      }
    }
  }, [utilitySelector.dialogOpen]);

  useEffect(() => {
    if (utilitySelector.filterOpen) {
      getDepartments('', false, true);
    }
  }, [utilitySelector.filterOpen]);

  const getRouteUrl = (page: number, pageSize: number, routeOrder: string) => {
    let route = `/api/Solicitacao/GetAll/?usuario=${userSelector.id}&tipoUs=${userSelector.tipo}`;

    if (filter.situation) {
      route = `${route}&situacao=${filter.situation.id}`;
    }

    if (filter.dateInclusionInitial) {
      const dateStr = datefns.format(
        toDate(filter.dateInclusionInitial),
        'MM-dd-yyyy'
      );
      route = `${route}&dataIn=${dateStr}`;
    }

    if (filter.dateInclusionFinal) {
      const dateStr = datefns.format(
        toDate(filter.dateInclusionFinal),
        'MM-dd-yyyy'
      );
      route = `${route}&dataFim=${dateStr}`;
    }

    if (filter.dateForecastInitial) {
      const dateStr = datefns.format(
        toDate(filter.dateForecastInitial),
        'MM-dd-yyyy'
      );
      route = `${route}&dataPrevIn=${dateStr}`;
    }

    if (filter.dateForecastFinal) {
      const dateStr = datefns.format(
        toDate(filter.dateForecastFinal),
        'MM-dd-yyyy'
      );
      route = `${route}&dataPrevFim=${dateStr}`;
    }

    if (filter.priority) {
      route = `${route}&prior=${filter.priority}`;
    }

    if (filter.branch) {
      route = `${route}&filial=${filter.branch?.id}`;
    }

    if (filter.departamentId) {
      route = `${route}&dep=${filter.departamentId}`;
    }

    if (filter.requestType) {
      route = `${route}&tipoSol=${filter.requestType?.id}`;
    }

    if (filter.myRequest) {
      route = `${route}&minSol=${filter.myRequest}`;
    }

    const skip = page * pageSize;
    route = `${route}&skip=${skip}&take=${pageSize}`;

    if (routeOrder !== '') {
      route = `${route}${routeOrder}`;
    }

    return route;
  };

  const getDepartments = async (
    filialId: string,
    acessoDepSelec: boolean,
    filterDep: boolean
  ) => {
    setIsDepLoading(true);
    try {
      const route = !acessoDepSelec
        ? `/api/Departamento/GetAll/?ativo=${true}`
        : `/api/Filial/GetAllAllowed/${filialId}`;

      await api.get<IDepartamento[]>(route).then(result => {
        const resp = result.data
          .filter(x => x.nivel === 1)
          .map(x => departmentTree(result.data, x));

        if (filterDep) {
          setFilterDepartment(resp);
        } else {
          setDepartment(resp);
        }

        setDeps(result.data);
      });
    } finally {
      setIsDepLoading(false);
    }
  };

  const getBranches = async () => {
    await api.get(`/api/Filial/GetAll/?ativo=${true}`).then(result => {
      setBranch(result.data);
    });
  };

  const getRequestType = async () => {
    await api.get(`/api/TipoSolicitacao/GetAll/?ativo=${true}`).then(result => {
      setRequestType(result.data);
    });
  };

  const getRequestFile = async (id: string) => {
    await api
      .get<ISolicitacaoArquivo[]>(`/api/SolicitacaoArquivo/GetAll/${id}`)
      .then(result => {
        setRequestFile(result.data);
      });
  };

  const getRequestsHistory = async (id: string) => {
    setIsLoadingHistory(true);

    await api
      .get<ISolicitacaoHistorico[]>(
        `/api/SolicitacaoHistorico/GetAll/${id}/?ordemDesc=${true}`
      )
      .then(result => {
        setRequestHistory(result.data);
        setIsLoadingHistory(false);
      })
      .catch(() => {
        setIsLoadingHistory(false);
      });
  };

  const createRequest = async (sol: ISolicitacao) => {
    const obj = {
      prioridade: sol.prioridade,
      descricao: sol.descricao,
      filialId: sol.filialId,
      departamentoId: sol.departamentoId,
      tipoSolicitacaoId: sol.tipoSolicitacaoId,
      observacao: sol.observacao,
    };

    const formData = new FormData();
    formData.append('solX', JSON.stringify(obj));

    sol.anexos?.forEach(x => {
      formData.append('files', x);
    });

    await api
      .post<ISolicitacao>(`api/Solicitacao/Create`, formData)
      .then(result => {
        result.data.descricaoFilial =
          sol?.filial?.nomeFantasia ?? sol?.filial?.razaoSocial ?? '';
        result.data.descricaoDepartamento = sol.departamento?.descricao;
        result.data.descricaoTipoSolicitacao = sol.tipoSolicitacao.descricao;
        result.data.nomeUsuario = userSelector.nome;
        result.data.nomeUsuarioResponsavel =
          sol.departamento?.nomeUsuarioResponsavel;
        sol.situacao = result.data.situacao;

        refreshQueryRequest(false);
      });
  };

  const onSubmit = async (formData: ISolicitacao) => {
    formData.filialId = formData.filial?.id ?? '';
    formData.tipoSolicitacaoId = formData.tipoSolicitacao?.id;
    if (formData.filial) {
      formData.filial.cpfCnpj =
        formData.filial?.cpfCnpj.replace(/[^0-9]/g, '') ?? '';
    }
    formData.departamento = deps.find(x => x.id === formData.departamentoId);

    await createRequest(formData);

    dispatch(closeDialog());
    reset({});
  };

  const handleClose = () => {
    dispatch(closeDialog());
    reset({});
  };

  const removeItem = (chipObj: IChip) => {
    if (isLoading) {
      return;
    }

    const fil = { ...filter };

    if (chipObj.id === '2') {
      fil.dateInclusionInitial = null;
    }

    if (chipObj.id === '3') {
      fil.dateInclusionFinal = null;
    }

    if (chipObj.id === '4') {
      fil.dateForecastInitial = null;
    }

    if (chipObj.id === '5') {
      fil.dateForecastFinal = null;
    }

    if (chipObj.id === '6') {
      fil.priority = null;
    }

    if (chipObj.id === '7') {
      fil.branch = null;
    }

    if (chipObj.id === '8') {
      fil.departamentId = undefined;
    }

    if (chipObj.id === '9') {
      fil.requestType = null;
    }

    if (chipObj.id === '10') {
      fil.myRequest = false;
    }

    setFilter(fil);
    resetFilters(fil);
    setChip(chip.filter(x => x !== chipObj));
  };

  const setChipsFilter = () => {
    const obj: IChip[] = [];

    if (filter.situation) {
      obj.push({
        id: '1',
        label: `Situação: ${filter.situation.description}`,
      });
    }

    if (filter.dateInclusionInitial) {
      const strDateInclusionInitial: string = filter.dateInclusionInitial
        ? datefns.format(toDate(filter.dateInclusionInitial), 'dd/MM/yyyy')
        : '';

      obj.push({
        id: '2',
        label: `Inc. Inicial: ${strDateInclusionInitial}`,
      });
    }

    if (filter.dateInclusionFinal) {
      const strDateInclusionFinal: string = filter.dateInclusionFinal
        ? datefns.format(toDate(filter.dateInclusionFinal), 'dd/MM/yyyy')
        : '';

      obj.push({
        id: '3',
        label: `Inc. Final: ${strDateInclusionFinal}`,
      });
    }

    if (filter.dateForecastInitial) {
      const strDateForecastInitial: string = filter.dateForecastInitial
        ? datefns.format(toDate(filter.dateForecastInitial), 'dd/MM/yyyy')
        : '';

      obj.push({
        id: '4',
        label: `Prev. Inicial: ${strDateForecastInitial}`,
      });
    }

    if (filter.dateForecastFinal) {
      const strDateForecastFinal: string = filter.dateForecastFinal
        ? datefns.format(toDate(filter.dateForecastFinal), 'dd/MM/yyyy')
        : '';

      obj.push({
        id: '5',
        label: `Prev. Final: ${strDateForecastFinal}`,
      });
    }

    if (filter.priority) {
      obj.push({
        id: '6',
        label: `Prioridade: ${filter.priority}`,
      });
    }

    if (filter.branch) {
      obj.push({
        id: '7',
        label: `Filial: ${filter.branch.razaoSocial}`,
      });
    }

    if (filter.departamentId) {
      const desc = deps.find(x => x.id === filter.departamentId)?.descricao;

      obj.push({
        id: '8',
        label: `Departamento: ${desc}`,
      });
    }

    if (filter.requestType) {
      obj.push({
        id: '9',
        label: `Tipo Solicitação: ${filter.requestType.descricao}`,
      });
    }

    if (filter.myRequest) {
      obj.push({
        id: '10',
        label: `Minhas Solicitações`,
      });
    }

    setChip(obj);
  };

  const handleFilterSubmit = async (formData: IFilters) => {
    setErroDateForecast(undefined);
    setErroDateInclusion(undefined);

    let erro = false;

    if (!formData.dateInclusionInitial || !formData.dateInclusionFinal) {
      /* if (formData.situation === null || formData.situation.id !== 'P') {
        setErroDateInclusion(
          'O período deve ser informado quando a situação for diferente de "Pendente"'
        );
        erro = true;
      } */
    } else {
      const daysBetween: number = datefns.differenceInDays(
        formData.dateInclusionFinal,
        formData.dateInclusionInitial
      );

      if (daysBetween < 0) {
        setErroDateInclusion('Período informado é inválido');
        erro = true;
      }

      if (daysBetween > 30) {
        setErroDateInclusion(
          'Período informado não pode ser superior a 30 dias'
        );
        erro = true;
      }
    }

    if (!formData.dateForecastInitial || !formData.dateForecastFinal) {
      /* if (formData.situation.id !== 'P') {
        setErroDateForecast(
          'O período deve ser informado quando a situação for diferente de "Pendente"'
        );
        return;
      } */
    } else {
      const daysBetween: number = datefns.differenceInDays(
        formData.dateForecastFinal,
        formData.dateForecastInitial
      );

      if (daysBetween < 0) {
        setErroDateForecast('Período informado é inválido');
        erro = true;
      }

      if (daysBetween > 30) {
        setErroDateForecast(
          'Período informado não pode ser superior a 30 dias'
        );
        erro = true;
      }
    }

    if (erro) {
      return;
    }

    setFilter(formData);
    dispatch(closeFilter());
  };

  const onClear = () => {
    setErroDateForecast(undefined);
    setErroDateInclusion(undefined);
    resetFilters(defaultFilter);
  };

  const onView = (id: string) => {
    history.push(`/solicitacoes/${id}`);
    localStorage.setItem('pageRequest', JSON.stringify(pageX));
  };

  const handleCloseHistory = () => {
    setOpenHistory(false);
    setRequestHistory([]);
  };

  const onSaveDropZone = (filesX: File[]) => {
    setFiles(filesX);
  };

  const onCloseDropZone = (count: number) => {
    if (count !== 0) {
      refreshQueryRequest(false);
    }

    setModifyArq(0);
    setRequestFile([]);
    setIsOpenDropZone(false);
  };

  const onUpload = async () => {
    if (files.length === 0) {
      snack.warning('Nenhum arquivo selecionado');
      return;
    }

    const formData = new FormData();

    files.forEach(x => {
      formData.append('files', x);
    });

    await api
      .patch<ISolicitacaoArquivo[]>(
        `api/SolicitacaoArquivo/Create/${solicitacao?.id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      .then(result => {
        onCloseDropZone(result.data.length);
      });
  };

  const deleteFile = async (id: string) => {
    await api
      .delete<ISolicitacaoArquivo>(`api/SolicitacaoArquivo/Delete/${id}`)
      .then(result => {
        setModifyArq(modifyArq - 1);
        setRequestFile(requestFile.filter(x => x.id !== result.data.id));
      });
  };

  return (
    <Page>
      {isLoadingPage ? (
        <div className={classes.progress}>
          <CircularProgress color="primary" />
        </div>
      ) : (
        <>
          {chip.length > 0 && (
            <Paper component="ul" className={classes.cardPaper}>
              <Hidden mdDown>
                <h3 style={{ marginTop: 10, marginLeft: 5 }}>Filtros:</h3>
              </Hidden>
              {chip.map(obj => {
                return (
                  <li key={obj.id}>
                    <Chip
                      label={obj.label}
                      deleteIcon={<Clear className={classes.icon} />}
                      className={classes.inactive}
                      variant="outlined"
                      size="medium"
                      onDelete={
                        obj.id !== '1' ? () => removeItem(obj) : undefined
                      }
                    />
                  </li>
                );
              })}
            </Paper>
          )}

          <MaterialTableX
            tableRef={tableRef}
            title={<h2>Solicitações ({totalCount})</h2>}
            columns={columns}
            style={{ marginTop: 10 }}
            isLoading={isLoading}
            onRefresh={() => refreshQueryRequest(false)}
            onView={x => onView(x)}
            data={query =>
              new Promise((resolve, reject) => {
                setIsLoading(true);

                setChipsFilter();

                const queryX = pageRequest ?? query;

                let routeOrder = '';

                if (queryX.orderBy) {
                  routeOrder = `&orderByField=${String(
                    queryX.orderBy.field
                  )}&orderByDirec=${queryX.orderDirection}`;
                }

                const route = getRouteUrl(
                  queryX.page,
                  queryX.pageSize,
                  routeOrder
                );

                setPageX(queryX);

                api
                  .get(route)
                  .then(result => {
                    resolve({
                      data: result.data.data,
                      page:
                        queryX.page <= result.data.totalPages ? queryX.page : 0,
                      totalCount: result.data.totalCount,
                    });

                    setTotalCount(result.data.totalCount);
                    setIsLoading(false);
                  })
                  .catch(() => {
                    setIsLoading(false);
                  });

                if (pageRequest) {
                  setPageRequest(undefined);
                  localStorage.removeItem('pageRequest');
                }
              })
            }
            showActionFilter
            hideActionEdit
            hideIsSearch
            // {...(userSelector.tipo === '4' && { hideActionAdd: true })}
            hideActionDelete
            detailPanel={(rowData: ISolicitacao) => {
              return (
                <Grid container spacing={2} className={classes.grid}>
                  <Grid item md={4} xs={4}>
                    <Typography component="span" variant="subtitle2">
                      Filial: {rowData.descricaoFilial}
                    </Typography>
                  </Grid>
                  <Grid item md={3} xs={3}>
                    <Typography component="span" variant="subtitle2">
                      Usuário: {rowData.nomeUsuario}
                    </Typography>
                  </Grid>
                  <Grid item md={3} xs={3}>
                    <Typography component="span" variant="subtitle2">
                      Responsável do Departamento:{' '}
                      {rowData.nomeUsuarioResponsavel}
                    </Typography>
                  </Grid>
                  <Grid item md={2} xs={2}>
                    <Typography component="span" variant="subtitle2">
                      {rowData.dataInclusao &&
                        datefns.formatDistanceToNow(
                          toDate(rowData.dataInclusao),
                          {
                            includeSeconds: false,
                            addSuffix: true,
                            locale: ptBR,
                          }
                        )}
                    </Typography>
                  </Grid>
                </Grid>
              );
            }}
          />

          <DialogForm
            titleDiag="Solicitações"
            subTitle="Informações"
            open={utilitySelector.dialogOpen}
            isLoading={isFetch}
            handleClose={handleClose}
            onSubmit={handleSubmit(onSubmit)}
          >
            <FormControl
              variant="standard"
              className={classes.formControl}
              fullWidth
            >
              <InputLabel htmlFor="type-required" shrink>
                Prioridade
              </InputLabel>
              <Controller
                name="prioridade"
                control={control}
                defaultValue="Baixa"
                render={({ field }) => (
                  <Select
                    {...field}
                    variant="standard"
                    color="primary"
                    label="Prioridade"
                    displayEmpty
                    onChange={field.onChange}
                    value={field.value || ''}
                  >
                    <MenuItem key="3" value="Baixa">
                      <Typography>
                        <Brightness1
                          style={{ color: '#006600', fontSize: 14 }}
                        />{' '}
                        Baixa
                      </Typography>
                    </MenuItem>
                    <MenuItem key="2" value="Normal">
                      <Typography>
                        <Brightness1
                          style={{ color: '#e6e600', fontSize: 14 }}
                        />{' '}
                        Normal
                      </Typography>
                    </MenuItem>
                    <MenuItem key="1" value="Alta">
                      <Typography>
                        <Brightness1
                          style={{ color: '#990000', fontSize: 14 }}
                        />{' '}
                        Alta
                      </Typography>
                    </MenuItem>
                  </Select>
                )}
              />
            </FormControl>

            <TextField
              className={classes.autoComplete}
              name="descricao"
              control={control}
              defaultValue=""
              variant="standard"
              fullWidth
              label="Descrição"
              autoFocus
              InputLabelProps={{ shrink: true }}
            />

            <Controller
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  {...field}
                  className={classes.autoComplete}
                  clearOnEscape
                  options={branch}
                  value={field.value || null}
                  noOptionsText="Sem opções"
                  openText="Abrir"
                  clearText="Limpar"
                  getOptionLabel={option => option.descricao}
                  getOptionSelected={(option, value) => option.id === value.id}
                  closeText="Fechar"
                  renderInput={params => (
                    <TextFieldCore
                      {...params}
                      label="Filial"
                      variant="standard"
                      InputLabelProps={{ shrink: true }}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                  onChange={(_, obj) => {
                    console.log(getValues());
                    reset({
                      ...getValues(),
                      filial: obj,
                      departamentoId: undefined,
                    });

                    if (obj) {
                      getDepartments(obj.id, obj.acessoDepSelec, false);
                    } else {
                      setDepartment([]);
                    }

                    field.onChange(obj);
                  }}
                  ref={field.ref}
                />
              )}
              name="filial"
              control={control}
            />

            <div>
              <InputLabel
                className={clsx(
                  classes.inheritedLabel,
                  errors.departamentoId && classes.errorLabel
                )}
                htmlFor="type-required"
                shrink
              >
                Departamento
              </InputLabel>

              <SelectTreeView
                control={control}
                errorForm={!!errors.departamentoId}
                name="departamentoId"
                treeData={department}
                treeNodeFilterProp="title"
                loading={isDepLoading}
              />

              {errors.departamentoId && (
                <span className={classes.error}>
                  {errors.departamentoId.message}
                </span>
              )}
            </div>

            <Controller
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  {...field}
                  className={classes.autoCompleteTop}
                  clearOnEscape
                  options={requestType}
                  value={field.value || null}
                  noOptionsText="Sem opções"
                  openText="Abrir"
                  clearText="Limpar"
                  getOptionLabel={option => option.descricao}
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderInput={params => (
                    <TextFieldCore
                      {...params}
                      label="Tipo de Solicitação"
                      variant="standard"
                      InputLabelProps={{ shrink: true }}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                  onChange={(_, obj) => field.onChange(obj)}
                  ref={field.ref}
                />
              )}
              name="tipoSolicitacao"
              control={control}
            />

            <Typography
              component="span"
              variant="caption"
              color="textSecondary"
            >
              Anexos
            </Typography>

            <Controller
              render={({ field }) => (
                <DropzoneArea
                  {...field}
                  classes={{
                    root: classes.dropZone,
                    icon: classes.dropZoneIcon,
                  }}
                  dropzoneParagraphClass={classes.paragraph}
                  onChange={field.onChange}
                  acceptedFiles={['image/*', 'application/*']}
                  dropzoneText="Arraste e solte um arquivo aqui ou clique"
                  alertSnackbarProps={{
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                  }}
                  previewText="Arquivos selecionados:"
                  getFileLimitExceedMessage={filesLimit =>
                    `Número máximo permitido de arquivos excedido. Só é permitido ${filesLimit} arquivos por envio`
                  }
                  getFileAddedMessage={fileName =>
                    `Arquivo ${fileName} adicionado com sucesso `
                  }
                  getFileRemovedMessage={fileName =>
                    `Arquivo ${fileName} removido`
                  }
                  maxFileSize={5000000}
                  previewChipProps={{ classes: { root: classes.previewChip } }}
                  useChipsForPreview
                  showPreviewsInDropzone
                  showPreviews={false}
                  showFileNames
                />
              )}
              name="anexos"
              control={control}
            />

            <TextField
              className={classes.autoComplete}
              name="observacao"
              control={control}
              defaultValue=""
              variant="outlined"
              fullWidth
              multiline
              rows={3}
              label="Observação"
              InputLabelProps={{ shrink: true }}
            />
          </DialogForm>
          <Filter
            onClear={onClear}
            onSubmit={handleSubmitFilters(handleFilterSubmit)}
          >
            <div className={classes.filters}>
              <Grid container spacing={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                  <Grid item md={6} xs={12}>
                    <DatePicker
                      name="dateInclusionInitial"
                      control={controlFilters}
                      label="Data Inicial"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <DatePicker
                      name="dateInclusionFinal"
                      control={controlFilters}
                      label="Data Final"
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>

              {erroDateInclusion && (
                <span style={{ fontSize: 12, color: 'red' }}>
                  {erroDateInclusion}
                </span>
              )}

              <Grid container spacing={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                  <Grid item md={6} xs={12}>
                    <DatePicker
                      name="dateForecastInitial"
                      control={controlFilters}
                      label="Previsão Inicial"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <DatePicker
                      name="dateForecastFinal"
                      control={controlFilters}
                      label="Previsão Final"
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>

              {erroDateForecast && (
                <span style={{ fontSize: 12, color: 'red' }}>
                  {erroDateForecast}
                </span>
              )}

              <FormControl
                variant="standard"
                className={classes.formControl}
                fullWidth
              >
                <InputLabel htmlFor="type-required" shrink>
                  Prioridade
                </InputLabel>
                <Controller
                  name="priority"
                  control={controlFilters}
                  render={({ field }) => (
                    <Select
                      {...field}
                      variant="standard"
                      color="primary"
                      label="Prioridade"
                      displayEmpty
                      onChange={field.onChange}
                      value={field.value || ''}
                    >
                      <MenuItem key="3" value="Baixa">
                        <Typography>
                          <Brightness1
                            style={{ color: '#006600', fontSize: 14 }}
                          />{' '}
                          Baixa
                        </Typography>
                      </MenuItem>
                      <MenuItem key="2" value="Normal">
                        <Typography>
                          <Brightness1
                            style={{ color: '#e6e600', fontSize: 14 }}
                          />{' '}
                          Normal
                        </Typography>
                      </MenuItem>
                      <MenuItem key="1" value="Alta">
                        <Typography>
                          <Brightness1
                            style={{ color: '#990000', fontSize: 14 }}
                          />{' '}
                          Alta
                        </Typography>
                      </MenuItem>
                    </Select>
                  )}
                />
              </FormControl>

              <Controller
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    {...field}
                    className={classes.autoCompleteTop}
                    clearOnEscape
                    options={branch}
                    value={field.value || null}
                    noOptionsText="Sem opções"
                    openText="Abrir"
                    clearText="Limpar"
                    getOptionLabel={option => option.descricao}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={params => (
                      <TextFieldCore
                        {...params}
                        label="Filial"
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                    onChange={(_, obj) => {
                      resetFilters({
                        ...getValuesFilters(),
                        departamentId: undefined,
                      });

                      if (obj) {
                        getDepartments(obj.id, obj.acessoDepSelec, true);
                      } else {
                        getDepartments('', false, true);
                      }

                      field.onChange(obj);
                    }}
                    ref={field.ref}
                  />
                )}
                name="branch"
                control={controlFilters}
              />

              <div>
                <InputLabel
                  className={clsx(
                    classes.inheritedLabel,
                    errorsFilters.departamentId && classes.errorLabel
                  )}
                  htmlFor="type-required"
                  shrink
                >
                  Departamento
                </InputLabel>

                <SelectTreeView
                  control={controlFilters}
                  errorForm={!!errorsFilters.departamentId}
                  name="departamentId"
                  treeData={filterDepartment}
                  treeNodeFilterProp="title"
                  loading={isDepLoading}
                />

                {errorsFilters.departamentId && (
                  <span className={classes.error}>
                    {errorsFilters.departamentId.message}
                  </span>
                )}
              </div>

              <Controller
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    {...field}
                    className={classes.autoCompleteTop}
                    clearOnEscape
                    options={requestType}
                    value={field.value || null}
                    noOptionsText="Sem opções"
                    openText="Abrir"
                    clearText="Limpar"
                    getOptionLabel={option => option.descricao}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={params => (
                      <TextFieldCore
                        {...params}
                        label="Tipo de Solicitação"
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                    onChange={(_, obj) => field.onChange(obj)}
                    ref={field.ref}
                  />
                )}
                name="requestType"
                control={controlFilters}
              />

              <Controller
                defaultValue={{
                  id: 'P',
                  description: 'Pendente',
                }}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    {...field}
                    className={classes.autoComplete}
                    options={situationType}
                    value={field.value || null}
                    noOptionsText="Sem opções"
                    openText="Abrir"
                    clearText="Limpar"
                    getOptionLabel={option => option.description}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={params => (
                      <TextFieldCore
                        {...params}
                        label="Situação"
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                    onChange={(_, obj) => field.onChange(obj)}
                    ref={field.ref}
                  />
                )}
                name="situation"
                control={controlFilters}
              />

              <FormControlLabel
                className={classes.formControlChecked}
                control={
                  <Controller
                    name="myRequest"
                    control={controlFilters}
                    defaultValue
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        color="primary"
                        onChange={e => field.onChange(e.target.checked)}
                        checked={field.value || false}
                      />
                    )}
                  />
                }
                label="Minhas Solicitações"
              />
            </div>
          </Filter>
          <Dialog
            titleDiag={`Solicitação: ${solicitacao?.codigo} - ${solicitacao?.descricao}`}
            open={openHistory}
            captionCancel="Fechar"
            maxWidth="xl"
            handleClose={handleCloseHistory}
          >
            <MaterialTableX
              title="Histórico"
              actionsOverride={actionsX}
              columns={columnsHistory}
              data={requestHistory}
              isLoading={isLoadingHistory}
              style={{ marginTop: 20 }}
              hideColumnsButton
              hideExportButton
            />
          </Dialog>
          <Dialog
            titleDiag={`Envio de Arquivos da Solicitação: ${solicitacao?.codigo} - ${solicitacao?.descricao}`}
            captionOk="Enviar"
            captionCancel="Cancelar"
            open={isOpenDropZone}
            onOk={onUpload}
            maxWidth="lg"
            handleClose={() => onCloseDropZone(modifyArq)}
          >
            <>
              {requestFile.length > 0 && (
                <>
                  <Typography component="span" variant="body1">
                    Arquivos anexados:
                  </Typography>

                  <ImageListDocuments
                    requestFile={requestFile}
                    solicitacaoId={solicitacao?.id || ''}
                    onDelete={deleteFile}
                  />
                </>
              )}

              <>
                <Typography component="span" variant="body1">
                  Arquivos a serem enviados:
                </Typography>

                <DropzoneArea
                  previewGridClasses={{
                    container: classes.dropZoneContainer,
                  }}
                  onChange={onSaveDropZone}
                  acceptedFiles={['image/*', 'application/*']}
                  dropzoneText="Arraste e solte um arquivo aqui ou clique"
                  alertSnackbarProps={{
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                  }}
                  previewText="Arquivos selecionados:"
                  getFileLimitExceedMessage={filesLimit =>
                    `Número máximo permitido de arquivos excedido. Só é permitido ${filesLimit} arquivos por envio`
                  }
                  getFileAddedMessage={fileName =>
                    `Arquivo ${fileName} adicionado com sucesso `
                  }
                  getFileRemovedMessage={fileName =>
                    `Arquivo ${fileName} removido`
                  }
                  maxFileSize={5000000}
                  showFileNames
                />
              </>
            </>
          </Dialog>
        </>
      )}
    </Page>
  );
};

export default Requests;
