import React, { ChangeEvent, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, Controller, FieldError } from 'react-hook-form';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import * as datefns from 'date-fns';
import { format, toDate } from 'date-fns-tz';
import { useHistory, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { DropzoneArea } from 'material-ui-dropzone';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Edit from '@material-ui/icons/Edit';
import FilterTiltShift from '@material-ui/icons/FilterTiltShift';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import Adjust from '@material-ui/icons/Adjust';
import ArrowForward from '@material-ui/icons/ArrowForward';
import {
  Add,
  AccessTime,
  AvTimer,
  Autorenew,
  Brightness1,
  Business,
  Category,
  ExpandMore,
  ChevronRight,
  Delete,
  Help,
  DynamicFeed,
  AddComment,
  Person,
  CheckCircle,
  Cancel,
  Timer,
  ThumbUp,
} from '@material-ui/icons';
import * as yup from 'yup';
import CalendarToday from '@material-ui/icons/CalendarToday';
import { Autocomplete } from '@material-ui/lab';
import {
  Avatar,
  Badge,
  CircularProgress,
  Chip,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Tab,
  Tabs,
  Typography,
  Tooltip,
  Paper,
  Button,
  TextField as TextFieldCore,
  Select,
} from '@material-ui/core';
import useUtility from '../../hooks/useUtility';
import api from '../../services/api';
import Page from '../../components/Page';
import TextField from '../../components/TextField';
import Dialog from '../../components/Dialog';
import DialogForm from '../../components/DialogForm';
import {
  IDepartamentoTree,
  ISolicitacao,
  ISolicitacaoArquivo,
  ISolicitacaoHistorico,
  IFilial,
  IDepartamento,
  ITipoSolicitacao,
} from '../../models';
import useLogin from '../../hooks/useLogin';
import getInitials from '../../utils/getInitials';
import ImageListDocuments from '../Requests/ImageListDocuments';
import snack from '../../components/SnackbarUtilsConfigurator';
import SelectTreeView from '../../components/SelectTreeView';
import TabPanel from '../../components/TabPanel';
import departmentTree from '../../utils/getDepartmentTree';
import styles from './styles';

type IRouteParams = {
  id: string;
};

interface StyledTabProps {
  label: string;
}

const StyledTabs = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
      backgroundColor: theme.palette.primary.main,
    },
  })
)(Tabs);

const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      minWidth: 72,
      fontWeight: 'normal',
      fontSize: 12,
      marginRight: theme.spacing(4),
      '&:hover': {
        color: '#40a9ff',
        opacity: 1,
      },
      '&$selected': {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
      },
      '&:focus': {
        color: theme.palette.primary.main,
      },
    },
    selected: {},
  })
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

const schema = yup
  .object()
  .shape({
    dataPrevisao: yup.date().nullable(),
    descricao: yup.string().required('Este campo é obrigatório'),
    filial: yup.object().required('Este campo é obrigatório').nullable(),
    departamentoId: yup.string().required('Este campo é obrigatório'),
    tipoSolicitacao: yup
      .object()
      .required('Este campo é obrigatório')
      .nullable(),
    observacao: yup.string().required('Este campo é obrigatório'),
  })
  .required();

const ViewRequest = () => {
  const paramsX = useParams();
  const { id } = paramsX as IRouteParams;
  const [request, setRequest] = useState<ISolicitacao>();
  const [requestFile, setRequestFile] = useState<ISolicitacaoArquivo[]>([]);
  const [branch, setBranch] = useState<IFilial[]>([]);
  const [historySol, setHistorySol] = useState<ISolicitacaoHistorico[]>([]);
  const [department, setDepartment] = useState<IDepartamentoTree[]>([]);
  const [deps, setDeps] = useState<IDepartamento[]>([]);
  const [requestType, setRequestType] = useState<ITipoSolicitacao[]>([]);
  const [maxIdhistory, setMaxIdhistory] = useState('');
  const [dataPrev, setDataPrev] = useState<Date | null>(null);
  const [obs, setObs] = useState('');
  const [comment, setComment] = useState('');
  const [erroMove, setErroMove] = useState('');
  const [erroDateForecast, setErroDateForecast] = useState('');
  const [isFetch, setIsFetch] = useState(false);
  const [openDocuments, setOpenDocuments] = useState(false);
  const [isOpenDropZone, setIsOpenDropZone] = useState(false);
  const [isDepLoading, setIsDepLoading] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [openHistory, setOpenHistory] = useState(true);
  const [nivel, setNivel] = useState(0);
  const [valueTab, setValueTab] = useState(0);
  const [comments, setComments] = useState(false);
  const [editComment, setEditComment] = useState(false);
  const { openDialog, closeDialog, utilitySelector } = useUtility();
  const { userSelector } = useLogin();
  const classes = styles();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    setError,
    formState: { errors },
  } = useForm<ISolicitacao>({
    resolver: yupResolver(schema),
  });

  const {
    control: controlComment,
    handleSubmit: handleSubmitComment,
    reset: resetComment,
  } = useForm<ISolicitacaoHistorico>();

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      history.push('/');
    }

    setNivel(1);

    async function fetchRequest() {
      setIsFetch(true);

      await api
        .get<ISolicitacao>(`api/Solicitacao/Get/${id}`)
        .then(result => {
          const nDep = result.data.departamento?.nivel ?? 0;

          if (nDep > 1) {
            setNivel(nDep - 1);
          }

          setRequest(result.data);
          setDataPrev(result.data.dataPrevisao);

          if (result.data === null) {
            history.push('/404');
            return;
          }

          getRequestsHistory();
          getRequestFile(id);

          setIsFetch(false);
        })
        .catch(() => {
          history.push('/404');
          setIsFetch(false);
        });
    }

    fetchRequest();
  }, []);

  const getRequestsHistory = async () => {
    await api
      .get<ISolicitacaoHistorico[]>(`/api/SolicitacaoHistorico/GetAll/${id}`)
      .then(result => {
        setHistorySol(result.data);

        const count = result.data.filter(x => x.situacao !== 'T').length;
        setMaxIdhistory(result.data[count - 1].id);
      })
      .catch(() => {
        setIsFetch(false);
      });
  };

  const getBranches = async () => {
    await api.get('/api/Filial/GetAll').then(result => {
      setBranch(result.data);
    });
  };

  const getDepartments = async (filialId: string, acessoDepSelec: boolean) => {
    setIsDepLoading(true);
    try {
      const route = !acessoDepSelec
        ? `/api/Departamento/GetAll/?ativo=${true}`
        : `/api/Filial/GetAllAllowed/${filialId}`;

      await api.get<IDepartamento[]>(route).then(result => {
        const resp = result.data
          .filter(x => x.nivel === 1)
          .map(x => departmentTree(result.data, x));
        setDepartment(resp);
        setDeps(result.data);
      });
    } finally {
      setIsDepLoading(false);
    }
  };

  const getRequestType = async () => {
    await api.get('/api/TipoSolicitacao/GetAll').then(result => {
      setRequestType(result.data);
    });
  };

  const onChangeDataPrev = (value: string | null | undefined) => {
    setErroDateForecast('');

    if (value) {
      const prev = datefns.parse(value, 'dd/MM/yyyy', new Date());
      setDataPrev(prev);
    } else {
      setDataPrev(null);
    }
  };

  const onChangeObs = (e: ChangeEvent<HTMLInputElement>) => {
    setErroMove('');
    setObs(e.target.value);
  };

  const onChangeComment = (e: ChangeEvent<HTMLInputElement>) => {
    setComment(e.target.value);
  };

  const updateRequest = async (situation: string) => {
    if (request) {
      const obj = {
        id: request.id,
        codigo: request.codigo,
        dataPrevisao: dataPrev || request.dataPrevisao,
        prioridade: request.prioridade,
        descricao: request.descricao,
        filialId: request.filialId,
        departamentoId: request.departamentoId,
        tipoSolicitacaoId: request.tipoSolicitacaoId,
        dataInclusao: request.dataInclusao,
        situacao: situation,
        usuarioId: request.usuarioId,
        observacao: obs,
      };

      await api
        .post<ISolicitacaoHistorico>(`api/SolicitacaoHistorico/Create`, obj)
        .then(result => {
          const req: ISolicitacao = request;
          req.dataPrevisao = obj.dataPrevisao;
          req.situacao = obj.situacao;
          // req.observacao = obj.observacao;
          req.descricaoSituacao = result.data.descricaoSituacao;

          result.data.nomeUsuario = userSelector.nome;

          setObs('');
          setRequest(req);
          setDataPrev(req.dataPrevisao);
          setMaxIdhistory(result.data.id);
          setHistorySol([...historySol, result.data]);
        });
    } else {
      snack.error('Objeto inválido');
    }
  };

  const update = async (req: ISolicitacao) => {
    const obj = {
      id: req.id,
      codigo: req.codigo,
      dataPrevisao: req.dataPrevisao,
      prioridade: req.prioridade,
      descricao: req.descricao,
      filialId: req.filialId,
      departamentoId: req.departamentoId,
      tipoSolicitacaoId: req.tipoSolicitacaoId,
      dataInclusao: req.dataInclusao,
      situacao: req.situacao,
      usuarioId: req.usuarioId,
      observacao: req.observacao,
    };

    await api.put<ISolicitacao>(`api/Solicitacao/Edit`, obj).then(result => {
      result.data.filial = req.filial;
      result.data.tipoSolicitacao = req.tipoSolicitacao;
      result.data.nomeUsuario = req.nomeUsuario;
      result.data.departamento = req.departamento;
      result.data.nomeUsuarioResponsavel =
        req.departamento?.nomeUsuarioResponsavel;

      setRequest(result.data);
      setDataPrev(result.data.dataPrevisao);

      if (result.data.situacao === 'P') {
        const updateTable = [...historySol];
        updateTable[updateTable.length - 1].observacao = result.data.observacao;
        setHistorySol(updateTable);
      }
    });
  };

  const onSubmit = async (formData: ISolicitacao) => {
    if (
      formData.situacao !== 'P' &&
      formData.situacao !== 'R' &&
      formData.dataPrevisao === null
    ) {
      setError('dataPrevisao', {
        type: 'manual',
        message:
          'Este campo é obrigatório quando a solicitação estiver em execução',
      });
      return;
    }

    formData.filialId = formData.filial?.id ?? '';
    formData.tipoSolicitacaoId = formData.tipoSolicitacao?.id;

    if (formData.filial) {
      formData.filial.cpfCnpj =
        formData.filial.cpfCnpj.replace(/[^0-9]/g, '') ?? '';
    }
    formData.departamento = deps.find(x => x.id === formData.departamentoId);

    await update(formData);

    dispatch(closeDialog());
    reset({});
  };

  const onSubmitComment = async (data: ISolicitacaoHistorico) => {
    await api
      .put<ISolicitacaoHistorico>(`api/SolicitacaoHistorico/Edit`, data)
      .then(result => {
        const updateTable = [...historySol];
        const index = updateTable.findIndex(x => x.id === data.id);
        updateTable[index] = result.data;
        setHistorySol(updateTable);

        setEditComment(false);
        resetComment({});
      });
  };

  const onAddComment = async () => {
    const obj = {
      solicitacaoId: request?.id,
      observacao: comment,
      situacao: 'T',
    };

    await api
      .post<ISolicitacaoHistorico>(`api/SolicitacaoHistorico/CreateHist`, obj)
      .then(result => {
        result.data.nomeUsuario = userSelector.nome;

        setHistorySol([...historySol, result.data]);
        setComment('');
        setComments(false);
      });
  };

  const onRemoveComment = async (
    solicitacaoHistorico: ISolicitacaoHistorico
  ) => {
    const obj = {
      solicitacaoId: request?.id,
      observacao: comment,
      situacao: 'T',
    };

    await api
      .delete(`api/SolicitacaoHistorico/Delete/${solicitacaoHistorico.id}`)
      .then(result => {
        const updateTable = historySol.filter(
          x => x.id !== solicitacaoHistorico.id
        );

        setHistorySol(updateTable);
      });
  };

  const onMove = async (situation: string) => {
    setErroDateForecast('');
    setErroMove('');

    if (
      (situation === 'P' || situation === 'C' || situation === 'S') &&
      obs === ''
    ) {
      setErroMove('Este campo é obrigatório');
      return;
    }

    if (
      (request?.situacao === 'R' || request?.situacao === 'A') &&
      situation === 'E'
    ) {
      if (dataPrev === null) {
        setErroDateForecast(
          'Este campo é obrigatório ao iniciar a execução da solicitação'
        );
        return;
      }
    } else {
      setDataPrev(null);
    }

    await updateRequest(situation);
  };

  const getSituation = (situation: string, forceWhite: boolean) => {
    let className;

    if (forceWhite) {
      className = classes.colorwhite;
    }

    if (situation === 'P') {
      return <FilterTiltShift className={className || classes.iconPending} />;
    }

    if (situation === 'C') {
      return <Cancel className={className || classes.iconCancel} />;
    }

    if (situation === 'A') {
      return <Timer className={className || classes.iconWait} />;
    }

    if (situation === 'E') {
      return <Autorenew className={className || classes.iconInProgress} />;
    }

    if (situation === 'R') {
      return <ThumbUp className={className || classes.iconAproved} />;
    }

    if (situation === 'F') {
      return <CheckCircle className={className || classes.iconChecked} />;
    }

    if (situation === 'S') {
      return <Help className={className || classes.iconRequestCancel} />;
    }

    if (situation === 'L') {
      return <Help className={className || classes.iconInProgress} />;
    }

    return <Adjust />;
  };

  const getClassNameSituation = (situation: string) => {
    if (situation === 'P') {
      return classes.iconPendingPhase;
    }

    if (situation === 'C') {
      return classes.iconCancelPhase;
    }

    if (situation === 'A') {
      return classes.iconWaitPhase;
    }

    if (situation === 'E') {
      return classes.iconInProgressPhase;
    }

    if (situation === 'R') {
      return classes.iconAprovedPhase;
    }

    if (situation === 'F') {
      return classes.iconCheckedPhase;
    }

    if (situation === 'S') {
      return classes.iconCancelPhase;
    }

    if (situation === 'L') {
      return classes.iconInProgressPhase;
    }

    return undefined;
  };

  const permissionCancel = () => {
    return userSelector.tipo === '1' || userSelector.permiteCancelarSolicitacao;
  };

  const getMoveSituation = () => {
    if (request?.situacao === 'P') {
      return (
        <>
          <Button
            variant="outlined"
            onClick={() => onMove('R')}
            className={classes.button}
            fullWidth
            color="primary"
            disabled={
              !userSelector.permiteAprovarSolicitacao &&
              userSelector.tipo !== '1'
            }
            endIcon={<ArrowForward>Aprovada</ArrowForward>}
          >
            Aprovada
          </Button>

          <Button
            variant="outlined"
            onClick={() =>
              onMove(
                userSelector.tipo === '1' ||
                  userSelector.permiteCancelarSolicitacao
                  ? 'C'
                  : 'S'
              )
            }
            className={classes.button}
            fullWidth
            color="primary"
            endIcon={
              <ArrowForward>
                {permissionCancel() ? 'Cancelada' : 'Cancelamento'}
              </ArrowForward>
            }
          >
            {permissionCancel() ? 'Cancelada' : 'Cancelamento'}
          </Button>
        </>
      );
    }

    if (request?.situacao === 'R') {
      return (
        <>
          <Button
            variant="outlined"
            onClick={() => onMove('A')}
            className={classes.button}
            fullWidth
            color="primary"
            endIcon={<ArrowForward>Aguardando</ArrowForward>}
          >
            Aguardando
          </Button>

          <Button
            variant="outlined"
            onClick={() => onMove('E')}
            className={classes.button}
            fullWidth
            color="primary"
            endIcon={<ArrowForward>Em Andamento</ArrowForward>}
          >
            Andamento
          </Button>
        </>
      );
    }

    if (request?.situacao === 'A') {
      return (
        <>
          <Button
            variant="outlined"
            onClick={() => onMove('E')}
            className={classes.button}
            fullWidth
            color="primary"
            endIcon={<ArrowForward>Em Andamento</ArrowForward>}
          >
            Andamento
          </Button>

          <Button
            variant="outlined"
            onClick={() => onMove('F')}
            className={classes.button}
            fullWidth
            color="primary"
            endIcon={<ArrowForward>Finalizada</ArrowForward>}
          >
            Finalizada
          </Button>
        </>
      );
    }

    if (request?.situacao === 'E') {
      return (
        <>
          <Button
            variant="outlined"
            onClick={() => onMove('A')}
            className={classes.button}
            fullWidth
            color="primary"
            endIcon={<ArrowForward>Aguardando</ArrowForward>}
          >
            Aguardando
          </Button>

          <Button
            variant="outlined"
            onClick={() => onMove('F')}
            className={classes.button}
            fullWidth
            color="primary"
            endIcon={<ArrowForward>Finalizada</ArrowForward>}
          >
            Finalizada
          </Button>
        </>
      );
    }

    if (request?.situacao === 'F') {
      return (
        <>
          <Button
            variant="outlined"
            onClick={() => onMove('P')}
            className={classes.button}
            fullWidth
            color="primary"
            endIcon={<ArrowForward>Reabrir</ArrowForward>}
          >
            Reabrir
          </Button>

          <Typography component="span" variant="body1">
            <CheckCircle className={classes.okIcon} color="secondary" />
            Solicitacação finalizada
          </Typography>
        </>
      );
    }

    if (request?.situacao === 'C') {
      return (
        <>
          <Button
            variant="outlined"
            onClick={() => onMove('P')}
            className={classes.button}
            fullWidth
            color="primary"
            endIcon={<ArrowForward>Reabrir</ArrowForward>}
          >
            Reabrir
          </Button>

          <Typography component="span" variant="body1">
            <Cancel className={classes.cancelIcon} />
            Solicitacação cancelada
          </Typography>
        </>
      );
    }

    if (request?.situacao === 'S' || request?.situacao === 'L') {
      return (
        <>
          <Button
            variant="outlined"
            onClick={() => onMove('P')}
            className={classes.button}
            fullWidth
            color="primary"
            endIcon={<ArrowForward>Pendente</ArrowForward>}
          >
            Pendente
          </Button>

          <Button
            variant="outlined"
            onClick={() => onMove('C')}
            className={classes.button}
            fullWidth
            color="primary"
            endIcon={<ArrowForward>Cancelada</ArrowForward>}
          >
            Cancelada
          </Button>
        </>
      );
    }

    return undefined;
  };

  /* eslint-disable  @typescript-eslint/ban-types */
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValueTab(newValue);
  };

  const cancelComment = () => {
    setComment('');
    setComments(false);
  };

  const getActivity = () => {
    return (
      <>
        <div style={{ display: 'flex', marginTop: 20 }}>
          <IconButton onClick={() => setHistoryOpen()} size="small">
            <Typography component="span" variant="body1">
              {openHistory ? (
                <ExpandMore className={classes.infoDocument} />
              ) : (
                <ChevronRight className={classes.infoDocument} />
              )}{' '}
              Atividade
            </Typography>
          </IconButton>

          <Divider className={classes.grow} />
        </div>

        {openHistory && (
          <div className={classes.tab}>
            <div className={classes.appbar}>
              <StyledTabs
                value={valueTab}
                onChange={handleChange}
                aria-label="tab activity"
              >
                <StyledTab label="Comentários" />
                <StyledTab label="Histórico" />
              </StyledTabs>
            </div>
            <TabPanel value={valueTab} index={0}>
              {historySol
                ?.filter(x => x.observacao !== '')
                .map((obj: ISolicitacaoHistorico) => {
                  return (
                    <div key={obj.id} style={{ marginBottom: 8 }}>
                      <div style={{ display: 'flex' }}>
                        <Avatar className={classes.avatarTab}>
                          {getInitials(obj?.nomeUsuario)}
                        </Avatar>

                        <Typography
                          component="span"
                          variant="caption"
                          className={classes.infoTab}
                        >
                          {obj?.nomeUsuario} adicionou um comentário -{' '}
                          {datefns.formatDistanceToNow(
                            toDate(obj.dataInclusao),
                            {
                              includeSeconds: false,
                              addSuffix: true,
                              locale: ptBR,
                            }
                          )}
                        </Typography>

                        <div className={classes.grow} />

                        {userSelector.id === obj.usuarioId &&
                          obj?.situacao !== 'P' && (
                            <>
                              <IconButton
                                onClick={() => commentEdit(obj)}
                                size="small"
                              >
                                <Tooltip title="Editar" placement="top-end">
                                  <Edit style={{ fontSize: 16 }} />
                                </Tooltip>
                              </IconButton>

                              {obj.situacao === 'T' && (
                                <IconButton
                                  onClick={() => onRemoveComment(obj)}
                                  size="small"
                                >
                                  <Tooltip title="Excluir" placement="top-end">
                                    <Delete
                                      style={{ fontSize: 16, marginLeft: 8 }}
                                    />
                                  </Tooltip>
                                </IconButton>
                              )}
                            </>
                          )}
                      </div>

                      <div style={{ marginTop: 10 }}>
                        <Typography component="span" variant="body2">
                          {obj.observacao}
                        </Typography>
                      </div>

                      <Divider className={classes.grow} />
                    </div>
                  );
                })}

              {comments ? (
                <>
                  <TextFieldCore
                    name="comentario"
                    variant="outlined"
                    fullWidth
                    multiline
                    value={comment}
                    onChange={onChangeComment}
                    rows={4}
                    autoFocus
                    placeholder="Comentário..."
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      classes: {
                        input: classes.resize,
                      },
                    }}
                  />

                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      variant="contained"
                      size="small"
                      className={classes.buttonCommentAction}
                      disabled={comment.length === 0}
                      onClick={() => onAddComment()}
                    >
                      Adicionar
                    </Button>

                    <Button
                      size="small"
                      className={classes.buttonCommentCancel}
                      onClick={() => cancelComment()}
                    >
                      Cancelar
                    </Button>
                  </div>
                </>
              ) : (
                <Button
                  size="small"
                  variant="contained"
                  style={{ textTransform: 'none', marginTop: 10 }}
                  startIcon={<AddComment />}
                  onClick={() => setComments(!comments)}
                >
                  Comentário
                </Button>
              )}
            </TabPanel>
            <TabPanel value={valueTab} index={1}>
              <Timeline align="left">
                {historySol
                  ?.filter(x => x.situacao !== 'T')
                  .map((obj: ISolicitacaoHistorico) => {
                    return (
                      <TimelineItem key={obj.id}>
                        <TimelineOppositeContent
                          className={classes.oppositeContent}
                        >
                          <Typography component="span" variant="body2">
                            {obj.descricaoSituacao}{' '}
                            {format(
                              toDate(obj.dataInclusao),
                              'dd/MM/yyyy HH:mm:ss'
                            )}
                          </Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot>
                            {getSituation(obj.situacao, false)}
                          </TimelineDot>
                          {maxIdhistory !== obj.id && <TimelineConnector />}
                        </TimelineSeparator>
                        <TimelineContent>
                          {obj.observacao && (
                            <Paper elevation={3}>
                              <TextFieldCore
                                name="observacao"
                                variant="outlined"
                                style={{ wordBreak: 'break-word' }}
                                disabled
                                fullWidth
                                multiline
                                value={obj.observacao}
                                maxRows={4}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                  classes: {
                                    root: classes.inputRoot,
                                    disabled: classes.disabled,
                                  },
                                }}
                              />
                            </Paper>
                          )}
                        </TimelineContent>
                      </TimelineItem>
                    );
                  })}
              </Timeline>
            </TabPanel>
          </div>
        )}
      </>
    );
  };

  const getStatistic = () => {
    if (historySol.length === 0) {
      return null;
    }

    const initial = toDate(historySol[0].dataInclusao);
    const final = toDate(historySol[historySol.length - 1].dataInclusao);

    const diff = datefns.formatDistance(final, initial, {
      includeSeconds: false,
      addSuffix: true,
      locale: ptBR,
    });

    return (
      <>
        <div style={{ marginTop: 50 }}>
          <Typography component="span" variant="h6">
            Informações do Processo
          </Typography>
        </div>
        <div className={classes.divs}>
          <Typography component="span" variant="body1">
            <AvTimer className={classes.infoIcon} color="secondary" />
            Início: {format(initial, 'dd/MM/yyyy HH:mm:ss')}
          </Typography>
        </div>
        <div className={classes.divs}>
          <Typography component="span" variant="body1">
            <AccessTime className={classes.infoIcon} color="secondary" />
            Fim: {format(final, 'dd/MM/yyyy HH:mm:ss')}
          </Typography>
        </div>
        <div className={classes.divs}>
          <Typography component="span" variant="body1">
            <Timer className={classes.infoIcon} color="secondary" />
            Tempo Decorrido: {diff}
          </Typography>
        </div>
      </>
    );
  };

  const handleClose = () => {
    dispatch(closeDialog());
    reset({});
  };

  const handleCloseComment = () => {
    setEditComment(false);
    resetComment({});
  };

  const commentEdit = (historico: ISolicitacaoHistorico) => {
    resetComment(historico);
    setEditComment(true);
  };

  const getOptionLabel = (option: any) => {
    return option.valueOf().descricao;
  };

  const onOpen = () => {
    getBranches();
    getRequestType();
    reset(request);

    if (request && request.filial) {
      getDepartments(request.filial.id, request.filial.acessoDepSelec);
    }

    dispatch(openDialog(''));
  };

  const verifyCardsInfo = () => {
    // prettier-ignore
    return (
      ((userSelector.tipo !== '2' ||
        request?.usuarioRespAprovId === userSelector.id && request?.situacao === 'L') &&
        (userSelector.permiteCancelarSolicitacao ||
          request?.situacao !== 'S')) ||
      userSelector.tipo === '1'
    );
  };

  const getInfoRequest = () => {
    if (verifyCardsInfo()) {
      return getActivity();
    }

    if (request?.situacao === 'C' || request?.situacao === 'F') {
      return getStatistic();
    }

    return undefined;
  };

  const getEdit = () => {
    if (
      (request?.situacao === 'P' ||
        (userSelector.tipo !== '2' &&
          request?.situacao !== 'F' &&
          request?.situacao !== 'C')) &&
      !userSelector.solicitarAprovacaoResponsavel
    ) {
      return (
        <IconButton onClick={onOpen} size="small">
          <Tooltip title="Editar" placement="top-end">
            <Edit className={classes.editIcon} />
          </Tooltip>
        </IconButton>
      );
    }

    return undefined;
  };

  const getDateForecast = () => {
    if (request) {
      if (request.dataPrevisao !== null) {
        return format(toDate(request.dataPrevisao), 'dd/MM/yyyy');
      }
    }

    return 'Sem previsão';
  };

  const getRequestFile = async (idX: string) => {
    await api
      .get<ISolicitacaoArquivo[]>(`/api/SolicitacaoArquivo/GetAll/${idX}`)
      .then(result => {
        setRequestFile(result.data);
      })
      .catch(() => {
        setIsFetch(false);
      });
  };

  const deleteFile = async (idX: string) => {
    await api
      .delete<ISolicitacaoArquivo>(`api/SolicitacaoArquivo/Delete/${idX}`)
      .then(result => {
        setRequestFile(requestFile.filter(x => x.id !== result.data.id));
      });
  };

  const setDocumentsOpen = () => {
    setOpenDocuments(!openDocuments);
  };

  const openDropZone = () => {
    setIsOpenDropZone(true);
  };

  const setHistoryOpen = () => {
    setOpenHistory(!openHistory);
  };

  const onSaveDropZone = (filesX: File[]) => {
    setFiles(filesX);
  };

  const onCloseDropZone = () => {
    setIsOpenDropZone(false);
  };

  const onUpload = async () => {
    if (files.length === 0) {
      snack.warning('Nenhum arquivo selecionado');
      return;
    }

    const formData = new FormData();

    files.forEach(x => {
      formData.append('files', x);
    });

    await api
      .patch<ISolicitacaoArquivo[]>(
        `api/SolicitacaoArquivo/Create/${request?.id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      .then(result => {
        const updateTable = [...requestFile];

        result.data.forEach(x => {
          updateTable.push(x);
        });

        setRequestFile(updateTable);
        setIsOpenDropZone(false);
      });
  };

  const returnColorPriority = (prioridade: string) => {
    if (prioridade === '3') {
      return (
        <Tooltip title="Prioridade baixa" placement="top">
          <Brightness1
            style={{ color: '#006600', fontSize: 16, marginRight: 15 }}
          />
        </Tooltip>
      );
    }

    if (prioridade === '2') {
      return (
        <Tooltip title="Prioridade normal" placement="top">
          <Brightness1
            style={{ color: '#e6e600', fontSize: 16, marginRight: 15 }}
          />
        </Tooltip>
      );
    }

    if (prioridade === '1') {
      return (
        <Tooltip title="Prioridade alta" placement="top">
          <Brightness1
            style={{ color: '#990000', fontSize: 16, marginRight: 15 }}
          />
        </Tooltip>
      );
    }

    return (
      <Brightness1
        style={{ color: '#000000', fontSize: 16, marginRight: 15 }}
      />
    );
  };

  const getErrorDateForecast = (error: FieldError | undefined) => {
    if (error) {
      if (error.type === 'manual') {
        return error.message;
      }

      return 'Data inválida';
    }

    return null;
  };

  const verifyEditUser = () => {
    return userSelector.tipo !== '4' || request?.usuarioId === userSelector.id;
  };

  const verifyRequestExecute = () => {
    return userSelector.tipo === '4';
  };

  return (
    <Page>
      {isFetch ? (
        <CircularProgress className={classes.progress} size={50} />
      ) : (
        <Grid container spacing={2} className={classes.main}>
          <Grid item md={5} xs={12}>
            <Paper elevation={3} className={classes.paper}>
              {returnColorPriority(request?.prioridade || '')}

              <Typography component="span" variant="h6">
                {request?.codigo} - {request?.descricao}
                {getEdit()}
              </Typography>

              <div style={{ marginTop: 5 }}>
                <Typography component="span" variant="caption">
                  Criado por {request?.nomeUsuario}{' '}
                  {historySol.length > 0 &&
                    datefns.formatDistanceToNow(
                      toDate(historySol[0].dataInclusao),
                      {
                        includeSeconds: false,
                        addSuffix: true,
                        locale: ptBR,
                      }
                    )}
                </Typography>
              </div>

              <div className={classes.divs}>
                <Typography component="span" variant="body1">
                  <CalendarToday
                    className={classes.infoIcon}
                    color="secondary"
                  />
                  Data de Previsão: {getDateForecast()}
                </Typography>
              </div>

              <div className={classes.divs}>
                <Typography component="span" variant="body1">
                  <Business className={classes.infoIcon} color="secondary" />
                  Filial:{' '}
                  {request?.filial?.nomeFantasia !== ''
                    ? request?.filial?.nomeFantasia
                    : request?.filial?.razaoSocial}
                </Typography>
              </div>

              <div className={classes.divs}>
                <Typography component="span" variant="body1">
                  <Category className={classes.infoIcon} color="secondary" />
                  Departamento: {request?.departamento?.descricao}
                </Typography>
              </div>

              <div className={classes.divs}>
                <Typography component="span" variant="body1">
                  <DynamicFeed className={classes.infoIcon} color="secondary" />
                  Tipo de Solicitação: {request?.tipoSolicitacao?.descricao}
                </Typography>
              </div>

              <div style={{ display: 'flex', marginTop: 20 }}>
                <Badge
                  color="primary"
                  badgeContent={requestFile.length}
                  max={99}
                  variant="dot"
                >
                  <IconButton onClick={() => setDocumentsOpen()} size="small">
                    <Typography component="span" variant="body1">
                      {openDocuments ? (
                        <ExpandMore className={classes.infoDocument} />
                      ) : (
                        <ChevronRight className={classes.infoDocument} />
                      )}{' '}
                      Anexos
                    </Typography>
                  </IconButton>
                </Badge>

                <Divider className={classes.grow} />

                <IconButton onClick={() => openDropZone()} size="small">
                  <Tooltip title="Adicionar Arquivos" placement="top-end">
                    <Add
                      className={classes.infoDocument}
                      style={{ fontSize: 20, marginTop: 3 }}
                    />
                  </Tooltip>
                </IconButton>
              </div>

              {openDocuments && (
                <ImageListDocuments
                  requestFile={requestFile}
                  solicitacaoId={id}
                  onDelete={deleteFile}
                />
              )}

              {getInfoRequest()}
            </Paper>
          </Grid>
          <Grid item md={verifyCardsInfo() ? 5 : 7} xs={12}>
            <Paper elevation={3} className={classes.paper}>
              <div style={{ display: 'flex' }}>
                <Typography component="span" variant="h6">
                  Fase Atual
                </Typography>

                <div className={classes.growFase} />

                <Chip
                  className={getClassNameSituation(request?.situacao || '')}
                  avatar={getSituation(request?.situacao || '', true)}
                  variant="outlined"
                  size="medium"
                  label={request?.descricaoSituacao}
                />
              </div>

              {request?.situacao !== 'C' &&
                request?.situacao !== 'F' &&
                userSelector.tipo !== '2' && (
                  <div>
                    <Typography component="span" variant="body1">
                      Preencha os campos abaixo e mova o para a próxima fase
                    </Typography>
                  </div>
                )}

              <div className={classes.divs}>
                <Typography component="span" variant="body1">
                  <Person className={classes.infoIcon} color="secondary" />
                  Responsável
                </Typography>
              </div>

              <div style={{ display: 'flex', marginTop: 20 }}>
                <Avatar className={classes.avatar}>
                  {getInitials(request?.nomeUsuarioResponsavel)}
                </Avatar>

                <Typography
                  component="span"
                  variant="body1"
                  style={{ padding: 10 }}
                >
                  {request?.nomeUsuarioResponsavel}
                </Typography>
              </div>

              {verifyCardsInfo() ? (
                <>
                  {(request?.situacao === 'R' || request?.situacao === 'A') && (
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                      <KeyboardDatePicker
                        disableToolbar
                        fullWidth
                        autoOk
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        minDate={new Date()}
                        label="Data de Previsão"
                        invalidDateMessage="Data inválida"
                        error={!!erroDateForecast}
                        helperText={
                          erroDateForecast !== '' ? erroDateForecast : null
                        }
                        value={dataPrev}
                        onChange={(date, value) => onChangeDataPrev(value)}
                        InputLabelProps={{ shrink: true }}
                      />
                    </MuiPickersUtilsProvider>
                  )}

                  <div className={classes.divs}>
                    <Typography component="span" variant="body1">
                      Ações que foram tomadas
                    </Typography>
                  </div>

                  <div style={{ marginBottom: 15 }}>
                    <Typography component="span" variant="caption">
                      Descreva detalhes na resolução da solicitação
                    </Typography>
                  </div>

                  <TextFieldCore
                    name="observacao"
                    variant="outlined"
                    fullWidth
                    multiline
                    value={obs}
                    onChange={onChangeObs}
                    rows={8}
                    error={!!erroMove}
                    helperText={erroMove !== '' ? erroMove : null}
                    placeholder="Digite aqui..."
                    InputLabelProps={{ shrink: true }}
                  />

                  {(request?.situacao === 'F' || request?.situacao === 'C') &&
                    getStatistic()}
                </>
              ) : (
                getActivity()
              )}
            </Paper>
          </Grid>

          {verifyCardsInfo() && (
            <Grid item md={2} xs={12}>
              <Paper elevation={3} className={classes.paper}>
                <Typography component="span" variant="h6">
                  Mover para
                </Typography>

                <div className={classes.divs}>{getMoveSituation()}</div>
              </Paper>
            </Grid>
          )}
        </Grid>
      )}
      <DialogForm
        titleDiag="Solicitação"
        subTitle="Informações"
        open={utilitySelector.dialogOpen}
        isLoading={isFetch}
        handleClose={handleClose}
        onSubmit={handleSubmit(onSubmit)}
      >
        {verifyEditUser() && (
          <FormControl
            variant="standard"
            className={classes.formControl}
            fullWidth
          >
            <InputLabel htmlFor="type-required" shrink>
              Prioridade
            </InputLabel>
            <Controller
              name="prioridade"
              control={control}
              defaultValue="1"
              render={({ field }) => (
                <Select
                  {...field}
                  variant="standard"
                  color="primary"
                  label="Prioridade"
                  displayEmpty
                  onChange={field.onChange}
                  value={field.value || ''}
                >
                  <MenuItem key="3" value="3">
                    <Typography>
                      <Brightness1 style={{ color: '#006600', fontSize: 14 }} />{' '}
                      Baixa
                    </Typography>
                  </MenuItem>
                  <MenuItem key="2" value="2">
                    <Typography>
                      <Brightness1 style={{ color: '#e6e600', fontSize: 14 }} />{' '}
                      Normal
                    </Typography>
                  </MenuItem>
                  <MenuItem key="1" value="1">
                    <Typography>
                      <Brightness1 style={{ color: '#990000', fontSize: 14 }} />{' '}
                      Alta
                    </Typography>
                  </MenuItem>
                </Select>
              )}
            />
          </FormControl>
        )}

        {verifyRequestExecute() && (
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <KeyboardDatePicker
                  {...field}
                  disableToolbar
                  fullWidth
                  autoOk
                  variant="inline"
                  format="dd/MM/yyyy"
                  minDate={new Date()}
                  margin="normal"
                  label="Data de Previsão"
                  invalidDateMessage="Data inválida"
                  error={!!error}
                  helperText={getErrorDateForecast(error)}
                  value={field.value || null}
                  inputRef={field.ref}
                  InputLabelProps={{ shrink: true }}
                  KeyboardButtonProps={{
                    'aria-label': 'data de previsão',
                  }}
                />
              )}
              name="dataPrevisao"
              control={control}
            />
          </MuiPickersUtilsProvider>
        )}

        {verifyEditUser() && (
          <>
            <TextField
              className={classes.autoComplete}
              name="descricao"
              control={control}
              defaultValue=""
              variant="standard"
              fullWidth
              label="Descrição"
              autoFocus
              InputLabelProps={{ shrink: true }}
            />

            <Controller
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  {...field}
                  className={classes.autoComplete}
                  clearOnEscape
                  options={branch}
                  value={field.value || null}
                  noOptionsText="Sem opções"
                  openText="Abrir"
                  clearText="Limpar"
                  getOptionLabel={option => option.descricao}
                  getOptionSelected={(option, value) => option.id === value.id}
                  closeText="Fechar"
                  renderInput={params => (
                    <TextFieldCore
                      {...params}
                      label="Filial"
                      variant="standard"
                      InputLabelProps={{ shrink: true }}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                  onChange={(_, obj) => {
                    reset({ ...getValues(), departamentoId: undefined });

                    if (obj) {
                      getDepartments(obj.id, obj.acessoDepSelec);
                    } else {
                      setDepartment([]);
                    }

                    field.onChange(obj);
                  }}
                  ref={field.ref}
                />
              )}
              name="filial"
              control={control}
            />

            <div>
              <InputLabel
                className={clsx(
                  classes.inheritedLabel,
                  errors.departamentoId && classes.errorLabel
                )}
                htmlFor="type-required"
                shrink
              >
                Departamento
              </InputLabel>

              <SelectTreeView
                control={control}
                errorForm={!!errors.departamentoId}
                name="departamentoId"
                treeData={department}
                treeNodeFilterProp="title"
                loading={isDepLoading}
              />

              {errors.departamentoId && (
                <span className={classes.error}>
                  {errors.departamentoId.message}
                </span>
              )}
            </div>

            <Controller
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  {...field}
                  className={classes.autoCompleteTop}
                  clearOnEscape
                  options={requestType}
                  value={field.value || null}
                  noOptionsText="Sem opções"
                  openText="Abrir"
                  clearText="Limpar"
                  getOptionLabel={option => option.descricao}
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderInput={params => (
                    <TextFieldCore
                      {...params}
                      label="Tipo de Solicitação"
                      variant="standard"
                      InputLabelProps={{ shrink: true }}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                  onChange={(_, obj) => field.onChange(obj)}
                  ref={field.ref}
                />
              )}
              name="tipoSolicitacao"
              control={control}
            />

            <TextField
              className={classes.autoComplete}
              name="observacao"
              control={control}
              defaultValue=""
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              label="Observação"
              InputLabelProps={{ shrink: true }}
            />
          </>
        )}
      </DialogForm>
      <Dialog
        titleDiag={`Envio de Arquivos da Solicitação: ${request?.codigo} - ${request?.descricao}`}
        captionOk="Enviar"
        open={isOpenDropZone}
        onOk={onUpload}
        maxWidth="lg"
        handleClose={onCloseDropZone}
      >
        <DropzoneArea
          previewGridClasses={{ container: classes.dropZoneContainer }}
          onChange={onSaveDropZone}
          acceptedFiles={['image/*', 'application/*']}
          dropzoneText="Arraste e solte um arquivo aqui ou clique"
          alertSnackbarProps={{
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          }}
          previewText="Arquivos selecionados:"
          getFileLimitExceedMessage={filesLimit =>
            `Número máximo permitido de arquivos excedido. Só é permitido ${filesLimit} arquivos por envio`
          }
          getFileAddedMessage={fileName =>
            `Arquivo ${fileName} adicionado com sucesso `
          }
          getFileRemovedMessage={fileName => `Arquivo ${fileName} removido`}
          maxFileSize={5000000}
          showFileNames
        />
      </Dialog>
      <DialogForm
        titleDiag="Solicitação"
        open={editComment}
        handleClose={handleCloseComment}
        onSubmit={handleSubmitComment(onSubmitComment)}
      >
        <TextField
          className={classes.autoComplete}
          name="observacao"
          control={controlComment}
          defaultValue=""
          variant="outlined"
          fullWidth
          multiline
          rows={8}
          label="Comentário"
          rules={{
            required: 'Este campo é obrigatório',
          }}
          InputLabelProps={{ shrink: true }}
        />
      </DialogForm>
    </Page>
  );
};

export default ViewRequest;
